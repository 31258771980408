import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-0 pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Row = _resolveComponent("Row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: `/customer-info/` + _ctx.customer.id
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.customer.fullName), 1)
          ]),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Row, {
        row: [{name:'Email', value: _ctx.customer.email}, {name:'Phone#', value: _ctx.customer.phone}]
      }, null, 8, ["row"]),
      _createVNode(_component_Row, {
        row: [{name:'Policy#', value: _ctx.customer.policyNum},{name:'Certificate#', value: _ctx.customer.certNum}]
      }, null, 8, ["row"])
    ])
  ]))
}