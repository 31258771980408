
import {defineComponent} from 'vue'
import Row from "@/components/base/common/Row.vue";

export default defineComponent({
  name: "CustomerCard",
  components: {Row},
  props: {
    customer: {}
  }
})
