
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {CompanySelect, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Cards from "@/components/base/common/Cards.vue";
import CustomerCard from "@/views/customer/CustomerCard.vue";

export default defineComponent({
  name: "ConfirmCustomers",
  components: {CustomerCard, Cards, KtDatatable, Entities},
  setup() {
    const objectFilter = ref({
      firstName: '',
      lastName: '',
      certNum: '',
      policyNum: '',
      status: 'CONFIRMED',
      clientId: ''
    })
    const tableHeader = ref([
      {
        name: "Email",
        key: "email",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Policy#",
        key: "policyNum",
        sortable: true,
      },
      {
        name: "Certificate#",
        key: "certificate",
        sortable: true,
      },
      {
        name: "Phone",
        key: "phone",
        sortable: true,
      },
    ]);
    const page = computed(() => store.state.CustomerModule.page);
    return {
      tableHeader,
      objectFilter,
      page,
      ...LoadFilterObjects(Actions.LOAD_CUSTOMERS, objectFilter.value, []),
      ...CompanySelect(),
    }
  },
  methods: {
    onDiscard() {
      this.objectFilter.firstName = '';
      this.objectFilter.lastName = '';
      this.objectFilter.certNum = '';
      this.objectFilter.policyNum = '';
      this.objectFilter.clientId = '';
        this.paginationLoad()
    },
    onSearch() {
      this.updateFilterObject(this.objectFilter)
    }
  }
})
